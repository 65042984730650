import { Box } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";

const LocalHTMLPage = (props) => {
  return (
    <div className="page">
      <Box
        className="page-content"
        sx={{
          height: "100%",
          // overflow: "hidden",
          paddingTop: {
            xs: "80px",
            // sm: "90px",
            md: "100px",
          },
        }}
      >
        <FrameWrapper html={process.env.PUBLIC_URL + props.link} />
      </Box>
    </div>
  );
};

const FrameWrapper = ({ html }) => {
  const ref = useRef();
  const [height, setHeight] = useState("0px");

  const updateHeight = () => {
    if (ref.current && ref.current.contentWindow && ref.current.contentWindow.document.body) {
      setHeight(ref.current.contentWindow.document.body.scrollHeight + 50 + "px");
    }
  };

  useEffect(() => {
    const iframe = ref.current;
    if (iframe) {
      iframe.addEventListener('load', updateHeight);
      const observer = new MutationObserver(updateHeight);
      observer.observe(iframe.contentWindow.document.body, { childList: true, subtree: true });

      window.addEventListener('resize', updateHeight);

      return () => {
        iframe.removeEventListener('load', updateHeight);
        observer.disconnect();
        window.removeEventListener('resize', updateHeight);
      };
    }
  }, [html]);

  return (
    <iframe
      ref={ref}
      src={html}
      height={height}
      width="100%"
      style={{
        border: "none",
        width: "100%",
        height: height,
      }}
    ></iframe>
  );
};

export default LocalHTMLPage;
